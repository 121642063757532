@font-face {
    font-family: "Roboto";
    src: url("./../assets/fonts/Roboto/Roboto-Light.ttf");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Roboto";
    src: url("./../assets/fonts/Roboto/Roboto-Regular.ttf");
    font-weight: 400;
    font-style: lighter;
    font-display: swap;
}

@font-face {
    font-family: "Roboto";
    src: url("./../assets/fonts/Roboto/Roboto-Black.ttf");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Overlock";
    src: url("./../assets/fonts/Overlock/Overlock-Regular.ttf");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Telex";
    src: url("./../assets/fonts/Telex/Telex-Regular.ttf");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Martel Sans";
    src: url("./../assets/fonts/Martel_Sans/MartelSans-Regular.ttf");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

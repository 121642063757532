#page {
    max-width: 100%;

    > div {
        align-items: center;
        flex-direction: column;

        > div {
            flex-direction: column;
            display: flex;
            gap: 1rem;

            div > p {
                color: var(--text);
                font-size: var(--fs-text);
                line-height: var(--fs-text);
                font-family: "Martel Sans";
                margin-bottom: 1rem;
            }

            pre {
                width: 100%;
                font-size: 1rem;
            }
        }
    }
}

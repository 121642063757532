#footer {
    > div {
        flex-direction: column;
        align-items: center;
        gap: 3rem;

        .social ul {
            display: flex;
            gap: 3rem;
            max-width: 600px;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;

            li {
                width: 64px;
                height: 64px;
                position: relative;

                a {
                    position: relative;
                    display: inline-block;
                    text-align: center;
                    padding-left: 4px;
                    line-height: 68px;
                    // justify-content: center;
                    // align-items: center;
                    width: 64px;
                    height: 64px;
                    color: var(--white);
                    font-size: 2rem;
                    transition: font-size 0.3s;

                    &::after {
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        border: 2px solid var(--white);
                        content: "";
                        -webkit-transition: all, 0.5s;
                        -o-transition: all, 0.5s;
                        transition: all, 0.5s;
                    }
                    &:hover::after {
                        transform: rotate(45deg);
                    }
                }
            }

            // @media screen and (max-width: 600px) {
            //     flex-direction: column;
            // }
        }
    }
}

#resume {
    > div {
        flex-direction: column;

        h2 {
            margin-bottom: 1.5rem;
        }

        > div:not(:last-of-type) {
            padding: 1.25rem 0;
            margin-bottom: 1.25rem;
            border-bottom: 2px solid var(--black);
        }

        div.buttons {
            display: flex;
            gap: 1rem;
            justify-content: center;

            form {
                width: 100%;
                max-width: 300px;
                button {
                    display: inline-block;
                    width: 100%;
                    max-width: 300px;
                }
            }
        }

        .header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 1rem;

            .image {
                width: 250px;
                height: 250px;
                background-color: gray;
            }

            @media screen and (max-width: 680px) {
                flex-direction: column-reverse;
            }
        }
    }
}

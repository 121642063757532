#about {
    > div {
        display: flex;
        gap: 1rem;
        align-items: center;

        .content {
            width: 50%;

            .title {
                position: relative;
                display: flex;
                align-items: flex-end;
                justify-content: center;

                h3.bgText {
                    position: absolute;
                    font-size: 10rem;
                    color: var(--bg-text);
                    top: 0;
                    left: 0;
                }

                h2 {
                    position: relative;
                    top: -3rem;
                    z-index: 3;
                }
            }

            .links {
                display: flex;
                flex-direction: column;
                gap: 0.25rem;

                p {
                    color: var(--white);
                    font-family: "Telex";
                    font-size: var(--fs-medium);

                    a {
                        color: var(--blue);

                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }

        .image {
            width: 50%;
        }

        @media screen and (max-width: 900px) {
            flex-direction: column;

            .content {
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 1rem;
                text-align: center;

                .title {
                    height: auto !important;
                    width: auto !important;
                    h3.bgText {
                        display: none;
                    }

                    h2 {
                        position: static;
                        top: 0;
                    }
                }
            }

            .image {
                display: none;
            }
        }
    }
}

#projects {
    > div {
        flex-direction: column;
        align-items: center;
        gap: 2rem;

        .projectsContainer {
            display: grid;
            width: 100%;
            grid-template-columns: repeat(3, 370px);
            justify-content: space-around;
            row-gap: 3rem;

            .wrapper {
                height: 270px;
                background-size: 100% 100%;
                background-position: center;
                display: flex;
                padding: 1rem;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: 3rem;
                position: relative;

                * {
                    transition: opacity 0.3s;
                    user-select: none;
                    opacity: 0;
                }

                &:hover * {
                    opacity: 1;
                    cursor: pointer;
                }

                .overlay {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: rgba(9, 17, 33, 0.88);
                    z-index: 1;
                }

                h3 {
                    position: relative;
                    z-index: 2;
                    color: var(--white);
                    font-size: 1.75rem;
                    font-weight: 400;
                    font-family: "Roboto";
                }

                p {
                    position: relative;
                    z-index: 2;
                }
            }

            @media screen and (max-width: 1220px) {
                grid-template-columns: repeat(2, 370px);
            }

            @media screen and (max-width: 900px) {
                grid-template-columns: repeat(1, 370px);
            }
        }
    }
}

#contact {
    display: grid;
    grid-template-columns: calc(100% - 400px) 400px;
    padding: 4%;
    justify-content: center;

    > div {
        h2 {
            margin-bottom: 2rem;
            font-weight: 400;
        }

        &:first-of-type {
            background-color: rgba(0, 4, 16, 0.5);
            margin: 0;
            flex-direction: column;
            padding: 2rem;

            form {
                width: 100%;

                .wrapper {
                    width: 100%;
                    margin-bottom: 2rem;

                    :global(.formError) {
                        border-bottom: 2px solid var(--red);
                        color: var(--red);

                        &::placeholder {
                            color: var(--red);
                        }
                    }

                    input {
                        width: 100%;
                        height: 42px;
                        border: none;
                        background: transparent;
                        border-bottom: 2px solid var(--white);
                        color: var(--white);
                        font-size: 1rem;
                        padding-left: 1rem;

                        &::placeholder {
                            font-size: 1rem;
                            color: var(--white);
                        }
                    }

                    textarea {
                        width: 100%;
                        min-height: 80px;
                        height: 120px;
                        max-height: 160px;
                        border: none;
                        background: transparent;
                        border-bottom: 2px solid var(--white);
                        color: var(--white);
                        font-size: 1rem;
                        padding: 1rem;
                        resize: vertical;

                        &::placeholder {
                            font-size: 1rem;
                            color: var(--white);
                        }
                    }

                    button {
                        width: 100%;
                        background-color: var(--blue);
                        border: none;
                        height: 42px;
                        font-size: 1.25rem;
                        color: var(--white);
                        font-size: "Roboto";
                        font-weight: 600;
                        cursor: pointer;
                        transition: background-color 0.3s;

                        &:hover {
                            background-color: #087561;
                        }
                    }
                }
            }
        }

        &:last-of-type {
            background-color: var(--black);
            flex-direction: column;
            margin: 0;
            padding: 2rem;

            h3 {
                color: var(--white);
                margin-bottom: 1rem;
                font-size: 1.25rem;
                font-family: "Telex";
            }

            .change {
                margin-top: 1.5rem;

                a {
                    color: var(--blue);
                    cursor: pointer;
                    font-size: 1.25rem;
                    font-family: "Telex";

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }

            .social ul {
                margin-top: 1rem;
                display: flex;
                gap: 1rem;

                li a {
                    color: var(--white);
                    font-size: 1.25rem;
                    transition: color 0.3s;

                    &:hover {
                        color: var(--gray);
                    }
                }
            }
        }
    }

    @media screen and (max-width: 1000px) {
        grid-template-columns: 1fr;

        &:last-of-type {
            text-align: center;

            .social {
                display: none;
            }
        }
    }
}

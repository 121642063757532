@mixin container {
    margin: 0 auto;
    padding: 0 2%;
    display: flex;
    width: 100%;
}

.container {
    @include container;
    max-width: 1280px;
}
.containerMin {
    @include container;
    max-width: 900px;
}

#services {
    > div {
        display: flex;
        flex-direction: row-reverse;
        gap: 2rem;
        align-items: center;

        .content {
            width: 50%;

            .title {
                position: relative;
                display: flex;
                align-items: flex-end;
                justify-content: center;

                h3.bgText {
                    position: absolute;
                    font-size: 10rem;
                    color: var(--bg-text);
                    top: 0;
                    left: 0;
                }

                h2 {
                    position: relative;
                    top: -3rem;
                    z-index: 3;
                }
            }

            .buttons {
                display: flex;
                flex-direction: row;
                gap: 1rem;
            }
        }

        .image {
            width: 50%;
        }

        @media screen and (max-width: 1320px) {
            flex-direction: column;

            .content {
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 1rem;
                text-align: center;

                .title {
                    height: auto !important;
                    width: auto !important;
                    h3.bgText {
                        display: none;
                    }
                }
            }

            .buttons {
                display: flex;
                flex-direction: row;
                gap: 1rem;
                justify-content: center;
            }

            .image {
                display: none;
            }
        }
    }
}

:root {
    --white: #f5f9ff;
    --black: #000410;
    --white-gray: #ced2dc;
    --gray: #7b7f88;
    --text: #babec7;
    --bg-text: #1e2a42;
    --blue-gray: #121e32;
    --black-gray: #091121;
    --green: #093320;
    --musg-green: #0e2929;
    --blue: #00a98b;
    --red: #ee2222;
    --fs-text: 1.25rem;
    --fs-medium: 1.563rem;
    --fs-large: 1.953rem;
    --fs-icon: 2.953rem;
}

#products {
    > div {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 3rem;

        .productsContainer {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            row-gap: 1rem;
            column-gap: 2rem;

            .wrapper {
                width: 400px;
                display: flex;
                flex-direction: column;
                gap: 1rem;

                .header {
                    display: flex;
                    align-items: center;
                    gap: 1rem;

                    h4 {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 56px;
                        height: 56px;
                        background-color: var(--blue);
                        border-radius: 100%;
                        color: var(--white);
                        font-family: "Roboto";
                        font-size: var(--fs-large);
                        font-weight: 400;
                    }

                    h3 {
                        color: var(--white);
                        font-family: "Telex";
                        font-size: var(--fs-medium);
                        font-weight: 400;
                    }
                }

                .content {
                    min-height: 56px;
                    padding-left: calc(55px / 2 + 1rem);
                    margin-left: calc(55px / 2);
                    border-left: 1px solid var(--gray);
                }
            }

            @media screen and (max-width: 1000px) {
                grid-template-columns: 1fr;
            }
        }
    }
}

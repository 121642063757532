#specialties {
    > div {
        flex-direction: column;
        align-items: center;
        gap: 2rem;

        .specialties {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            column-gap: 3rem;
            row-gap: 3rem;
            align-items: center;

            .wrapper {
                justify-self: center;
                max-width: 350px;
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 1rem;
                color: var(--white);

                i {
                    font-size: var(--fs-icon);
                }

                h3 {
                    font-family: "Roboto";
                    font-weight: 300;
                    font-size: var(--fs-medium);
                }

                p {
                    text-align: center;
                }
            }

            @media screen and (max-width: 1000px) {
                grid-template-columns: 1fr 1fr;
            }

            @media screen and (max-width: 680px) {
                grid-template-columns: 1fr;
            }
        }
    }
}

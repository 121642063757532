@import "./Header.module.scss";

#banner {
    background-image: url("../../assets/images/bg.jpeg");
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    height: 110vh;
    position: relative;
    padding: 0;

    .overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgba(9, 17, 33, 0.7);
        z-index: 1;
    }

    > div:last-of-type {
        position: relative;
        height: 100%;
        flex-direction: column;

        .content {
            height: calc(80% - 70px);
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 5%;
            z-index: 1;

            .infos {
                display: flex;
                flex-direction: column;
                gap: 1rem;
                max-width: 500px;

                h2 {
                    color: var(--white);
                    font-weight: 400;
                    font-size: 2.441rem;
                }

                p {
                    color: #ced2dc;
                    font-family: "Telex";
                }

                .buttons {
                    display: flex;
                    gap: 1rem;
                }
            }

            .social {
                ul {
                    display: flex;
                    flex-direction: column;
                    gap: 1rem;

                    a {
                        color: var(--white);
                        width: 100%;
                        display: flex;
                        gap: 0.5rem;
                        align-items: center;
                        flex-direction: row-reverse;
                        cursor: default;

                        span:last-of-type {
                            font-family: "Martel Sans";
                            animation-direction: reverse;
                            visibility: hidden;
                            font-size: 0.75rem;
                            margin-right: -0.5rem;

                            opacity: 0;
                            animation-duration: 0.3s;
                            animation-name: socialTextOut;
                            animation-iteration-count: 1;
                            animation-fill-mode: forwards;
                            animation-direction: normal;
                        }

                        span:first-of-type {
                            cursor: pointer;
                            width: 34px;
                            height: 34px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            font-size: 20px;
                            border: 1px solid var(--white);
                            border-radius: 100%;
                        }

                        span:hover ~ span {
                            animation-duration: 0.3s;
                            animation-name: socialTextIn;
                            animation-iteration-count: 1;
                            animation-fill-mode: forwards;
                            animation-direction: normal;
                        }
                    }
                }
            }
        }
    }
}

@keyframes socialTextIn {
    from {
        opacity: 0;
        font-size: 0.75rem;
        margin-right: -0.5rem;
        visibility: hidden;
    }
    to {
        opacity: 1;
        visibility: visible;
        font-size: 1rem;
        margin-right: 0;
    }
}

@keyframes socialTextOut {
    from {
        opacity: 1;
        visibility: visible;
        font-size: 1rem;
        margin-right: 0;
    }
    to {
        opacity: 0;
        font-size: 0.75rem;
        margin-right: -0.5rem;
        visibility: hidden;
    }
}

@import "./variables.scss";
@import "./fonts.scss";

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;

    &::selection {
        background-color: #f5f9ff;
        color: #091121;
    }
}

html,
body,
.App {
    overflow-x: hidden;
}

body {
    background: var(--black-gray);
}

body.menu {
    overflow-y: hidden;
}

section,
footer {
    padding: 60px 0;
}

h1,
h2,
h3 {
    font-family: "Roboto";
}

@media (max-width: 1080px) {
    html {
        font-size: 93.75%;
    }
}
@media (max-width: 720px) {
    html {
        font-size: 87.5%;
    }
}

// scroll bar

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}
::-webkit-scrollbar-thumb {
    background: #babec7;
    border-radius: 30px;
}
::-webkit-scrollbar-thumb:hover {
    background: #f0f0f0;
}
::-webkit-scrollbar-track {
    background: #000410;
    border-radius: 30px;
    box-shadow: inset 0px 0px 0px 0px #f0f0f0;
}

.loading {
    height: 50vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    p {
        color: var(--white);
        font-size: var(--fs-large);
    }
}

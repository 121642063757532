#navigation {
    width: 100%;

    > div {
        display: flex;
        gap: 1.25rem;
        align-items: center;
        justify-content: left;
        width: 100%;

        span {
            color: var(--white);
            font-size: var(--fs-text);
        }

        .navigationLink {
            color: var(--white);
            font-family: "Roboto";
            font-size: var(--fs-text);

            div {
                width: 10px;
                height: 2px;
                background-color: var(--white);
                transition: width 0.5s;
            }

            &:hover div {
                width: 100%;
            }
        }
    }
}
